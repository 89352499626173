import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { FetchPolicy } from '@core/enums/fetch-policy-apollo.enum';
import {
  AdminListFilterGqlType,
  OffsetPaginationInput,
  AdminListResponse,
  CreateAdminQueryResult,
  CreateAdminGqlDto,
  UpdateAdminQueryResult,
} from '@core/interfaces/users.interface';
import { ArchiveResponse } from '@core/interfaces/vehicle-info.interface';
import { ArchiveUser, CreateAdmin, UpdateUser } from '@core/store/action/users.action';
import { Store } from '@ngxs/store';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private readonly apollo: Apollo,
    private store: Store,
  ) {}

  getListUser(): Observable<ApolloQueryResult<AdminListResponse>> {
    return this.apollo.query<AdminListResponse>({
      query: gql`
        query GetListAdmin($filter: AdminListFilterGqlType, $pagination: OffsetPaginationInput) {
          getListAdmin(filter: $filter, pagination: $pagination) {
            result {
              id
              firstName
              lastName
              middleName
              mobilePhone
              isTemporaryPassword
              status
              role {
                id
                name
              }
              status
              createdAt
              updatedAt
              archivedAt
              canUpdate
              canArchive
            }
            total
          }
        }
      `,
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  public createUser(adminData: CreateAdminGqlDto): Observable<MutationResult<CreateAdminQueryResult>> {
    return this.apollo
      .mutate<CreateAdminQueryResult>({
        mutation: gql`
          mutation createAdmin($data: CreateAdminGqlDto!) {
            createAdmin(data: $data) {
              id
              firstName
              lastName
              mobilePhone
              locale
              middleName
              roleId
              status
              createdAt
              canArchive
              canUpdate
              archivedAt
              archivedById
              createdById
              updatedAt
              updatedById
              role {
                id
                name
              }
            }
          }
        `,
        variables: {
          data: adminData,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new CreateAdmin(res.data.createAdmin));
          }
        }),
      );
  }

  public updateUser(data: CreateAdminGqlDto, id: string): Observable<MutationResult<UpdateAdminQueryResult>> {
    return this.apollo
      .mutate<UpdateAdminQueryResult>({
        mutation: gql`
          mutation updateAdmin($data: UpdateAdminGqlDto!, $id: String!) {
            updateAdmin(data: $data, id: $id) {
              firstName
              middleName
              lastName
              archivedAt
              archivedBy {
                id
                mobilePhone
              }
              archivedById
              canArchive
              canUpdate
              createdAt
              createdBy {
                id
                mobilePhone
              }
              createdById
              id
              isTemporaryPassword
              locale
              locationFilter {
                cityId
              }
              mobilePhone
              role {
                id
                name
              }
              updatedAt
              updatedBy {
                id
                mobilePhone
              }
              updatedById
            }
          }
        `,
        variables: {
          data,
          id,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new UpdateUser(res.data.updateAdmin));
          }
        }),
      );
  }

  public archiveUser(id: number): Observable<MutationResult<{ archiveAdmin: ArchiveResponse }>> {
    return this.apollo
      .mutate<{ archiveAdmin: ArchiveResponse }>({
        mutation: gql`
          mutation archiveAdmin($id: String!) {
            archiveAdmin(id: $id) {
              id
              firstName
              lastName
              archivedAt
              archivedById
              canArchive
              status
            }
          }
        `,
        variables: { id },
      })
      .pipe(
        tap((res) => {
          if (res.data?.archiveAdmin) {
            this.store.dispatch(new ArchiveUser(Number(res.data.archiveAdmin.id)));
          }
        }),
      );
  }
}
