import { GetListParams } from '@core/interfaces/get-list.interface';
import { LocationFilter } from '@core/interfaces/time-window.interface';
import { User } from '@core/interfaces/users.interface';

export class GetListUsers {
  static readonly type = '[GetListUsers] Get List Users';

  constructor(public params: GetListParams<LocationFilter>) {}
}

export class CreateAdmin {
  static readonly type = '[Admin] Create Admin';

  constructor(public user: User) {}
}

export class UpdateUser {
  static readonly type = '[User] Update User';

  constructor(public user: User) {}
}

export class ArchiveUser {
  static readonly type = '[GetListUsers] Get List Users';

  constructor(public id: number) {}
}
