/* eslint-disable */

import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { ArchiveUser, CreateAdmin, GetListUsers, UpdateUser } from '../action/users.action';
import { UsersService } from '@core/services/users.service';
import { User } from '@sentry/angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { CreateAdminQueryResult, UpdateAdminQueryResult } from '@core/interfaces/users.interface';

export class UsersStateModel {
  users!: Array<User>;
  total!: number | null;
}

@State<UsersStateModel>({
  name: 'UsersState',
  defaults: {
    users: [],
    total: null,
  },
})
@Injectable()
export class UsersState {
  constructor(private usersService: UsersService) {}

  @Selector()
  static getUsers(state: UsersStateModel): any {
    return state.users;
  }

  @Action(GetListUsers)
  public getListTimeWindow(ctx: StateContext<UsersStateModel>, { params }: GetListUsers) {
    return this.usersService.getListUser().pipe(
      tap((res) => {
        if (res && res.data) {
          ctx.patchState({ users: res.data.getListAdmin.result });
          ctx.patchState({ total: res.data.getListAdmin.total });
        }
      }),
    );
  }

  @Action(CreateAdmin)
  public createAdmin(ctx: StateContext<UsersStateModel>, { user }: CreateAdmin): void {
    const currentState = ctx.getState();
    const updatedVehicles = [...currentState.users, user];
    ctx.patchState({
      users: updatedVehicles,
    });
  }

  @Action(UpdateUser)
  public updateVehicle(ctx: StateContext<UsersStateModel>, { user }: UpdateUser): void {
    const currentState = ctx.getState();

    const updatedVehicles = [...currentState.users];
    const vehicleIndex = updatedVehicles.findIndex((v) => v.id === user.id);

    if (vehicleIndex !== -1) {
      updatedVehicles[vehicleIndex] = { ...updatedVehicles[vehicleIndex], ...user };
    }

    ctx.patchState({
      users: updatedVehicles,
    });
  }

  @Action(ArchiveUser)
  public archiveVehicle(ctx: StateContext<UsersStateModel>, { id }: ArchiveUser): void {
    const currentState = ctx.getState();

    const updatedVehicles = currentState.users.filter((user) => user.id !== id);

    ctx.patchState({
      users: updatedVehicles,
    });
  }
}
